body {
  background: #fafafa;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  font-size: 12px; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px; }
  .navbar-brand.logo .logo-img {
    height: 45px;
    display: inline-block; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover, .hand, [jh-sort-by] {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td vertically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* ==========================================================================
Social button
========================================================================== */
.jh-btn-social {
  margin-bottom: 5px; }

.jh-btn-google {
  background-color: #dd4b39;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff; }

.jh-btn-google:hover, .jh-btn-google:focus, .jh-btn-google:active, .jh-btn-google.active, .open > .dropdown-toggle.jh-btn-google {
  background-color: #c23321;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff; }

.jh-btn-facebook {
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff; }

.jh-btn-facebook:hover, .jh-btn-facebook:focus, .jh-btn-facebook:active, .jh-btn-facebook.active, .open > .dropdown-toggle.jh-btn-facebook {
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff; }

.jh-btn-twitter {
  background-color: #55acee;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff; }

.jh-btn-twitter:hover, .jh-btn-twitter:focus, .jh-btn-twitter:active, .jh-btn-twitter.active, .open > .dropdown-toggle.jh-btn-twitter {
  background-color: #2795e9;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
.activity-PRAXEDO {
  text-decoration: line-through; }

.activity-CONSTRUCTEL, .activity-ERROR, .activity-ERROR_QTY {
  color: red; }

tr.derivedPoint {
  font-size: 11px; }

tr.derivedPoint td:nth-child(1) {
  padding-left: 15px; }

.hightlightDayCalendar {
  font-weight: bolder;
  color: #337ab7; }

.tdYellow {
  background: #fffe54; }

.tdPink {
  background: #eb3df7; }

.tdGrey {
  background: #808080; }

.tdBlue {
  background: #699ad0; }

.tdRed {
  background: #dc3545; }

.tdGreen {
  background: #7daa55; }

.tdOrange {
  background: #ffc000; }

.tdGreySunday {
  background: #abb0af; }

.tdLightBrown {
  background: #7c611d; }

.tdYellowChef {
  background: #f6c142; }

.tdBeigeBinome {
  background: #fbe6a3; }

.tdPurple {
  background: #a23cd5; }

option:not(:disabled) {
  font-weight: bolder; }

.breakline::after {
  content: "\a";
  white-space: pre; }

.tdAlignCenter {
  vertical-align: middle !important;
  text-align: center; }

.fa-ambulance:before {
  color: white !important; }

.borderTopAndBottom {
  border-bottom: black solid 1px !important;
  border-top: black solid 1px !important; }

.borderLeft {
  border-left: black solid 1px !important; }

.borderRight {
  border-right: black solid 1px !important; }

.bottomBorderBolder {
  border-bottom: black solid 2px !important; }

.astreinteAlert {
  color: red;
  font-weight: bolder; }

.hightlightRow tr:hover {
  background: #ffa !important; }

/* ==========================================================================
Intervention
========================================================================== */
.modal-xl .modal-dialog {
  width: 90%; }

.modal-l .modal-dialog {
  width: 60%; }

.praxedo-id-input {
  max-width: 45%; }

.intervention-form-part1 {
  display: flex;
  justify-content: space-between; }

.custom-form-inline {
  display: inline-block; }

.form-block {
  width: 45%; }

.form-block .group {
  background-color: #EEEEEE;
  padding: 2rem; }

.form-block .group2 {
  margin-top: 5rem; }

.form-technicien, .form-activity, .form-comment {
  margin-top: 2rem;
  background-color: #EEEEEE;
  padding: 2rem; }

.form-technicien .techniciens, .form-activity .activities {
  padding-left: 5rem; }

.form-technicien .techniciens .new-technicien {
  width: 70%;
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  margin-top: 2rem; }

.form-activity .activities .new-activity {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 70%;
  margin-top: 2rem; }

.form-activity .activities .new-activity .select-activity {
  width: 50%; }

.form-activity .activities .new-activity .select-qty {
  width: 20%; }

.form-activity .activities .new-activity .select-qty .add-btn {
  width: 20%; }

.group6 {
  margin-top: 2rem;
  background-color: #EEEEEE;
  padding: 2rem; }

.planning-equipe .equipe-one-day {
  display: flex;
  flex-direction: column; }

.planning-equipe .groupe-title {
  background-color: #3b5998;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  padding: .5rem; }

.planning-equipe .groupe-planning {
  background-color: lightsteelblue; }

textarea {
  resize: vertical; }

.text-red {
  color: red; }

.text-green {
  color: limegreen; }

.tech-percentage {
  display: flex;
  justify-content: start;
  gap: 10rem; }
  .tech-percentage .edit-bloc {
    width: 30%; }
    .tech-percentage .edit-bloc input {
      max-width: 90px; }

/* ==========================================================================
Home
========================================================================== */
.infos {
  padding-left: 2rem; }
  .infos .last-imports {
    display: flex;
    align-items: center;
    gap: 5rem;
    margin-bottom: .3rem; }
  .infos .legende {
    display: flex;
    gap: 1rem;
    margin-top: 1rem; }

.theoricalPercentage {
  width: 1.5px;
  background-color: red; }

.one-stat-container .progress-bar-container {
  display: flex;
  padding: 0 2rem;
  align-items: center;
  justify-content: space-between; }
  .one-stat-container .progress-bar-container .tech-name {
    font-weight: bold; }
  .one-stat-container .progress-bar-container .view-calendar-btn:hover {
    cursor: pointer; }
  .one-stat-container .progress-bar-container .progress-bar-column {
    width: 60%;
    position: relative; }
    .one-stat-container .progress-bar-container .progress-bar-column .progress {
      position: relative; }
      .one-stat-container .progress-bar-container .progress-bar-column .progress::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 2px;
        background-color: #000; }
    .one-stat-container .progress-bar-container .progress-bar-column .theoricalPercentage {
      position: absolute;
      top: 30%;
      transform: translateX(-50%);
      height: 35%; }

.home-calendar {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0 1rem;
  margin-top: 2rem; }
  .home-calendar .one-week .calendar-week {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between; }
    .home-calendar .one-week .calendar-week .one-day {
      flex: 1; }
      .home-calendar .one-week .calendar-week .one-day .date, .home-calendar .one-week .calendar-week .one-day .content {
        padding: .8rem;
        border: 1px solid;
        min-height: 35px;
        min-width: 16%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; }
      .home-calendar .one-week .calendar-week .one-day .date {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center; }
      .home-calendar .one-week .calendar-week .one-day .content {
        display: flex;
        align-items: center;
        align-content: center; }

.home-activities {
  padding: 1rem 1rem; }

/* ==========================================================================
Details
 */
.createAstreinte {
  display: flex;
  align-items: flex-end;
  gap: 1rem; }

/* ==========================================================================
Outil
========================================================================== */
.outil-prices {
  display: flex;
  justify-content: space-between; }
  .outil-prices .adaptPriceBtns {
    margin: .5rem 2rem;
    display: flex;
    justify-content: left;
    flex-direction: column;
    font-size: 2.3rem; }
    .outil-prices .adaptPriceBtns div:hover {
      cursor: pointer; }
      .outil-prices .adaptPriceBtns div:hover span svg {
        fill: #286090; }
  .outil-prices .form-group {
    width: 40%; }

/* ==========================================================================
Calendar
========================================================================== */
.clickable {
  cursor: pointer; }

.non-clickable {
  cursor: default; }

.tooltip {
  font-size: 14px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  padding: 8px; }

/* ==========================================================================
Planning equipe
========================================================================== */
.planning-equipe {
  display: flex;
  justify-content: space-between; }
  .planning-equipe .day-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .planning-equipe .day-topbar .technicien-ratio {
      font-weight: bold;
      font-size: 1.2rem; }

.planning-equipe .equipe-day-group {
  width: 20%;
  display: flex; }

.planning-equipe .copyBtns {
  margin-top: 10rem;
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; }

.planning-equipe .copyBtns .dayCopyBtn {
  width: 70%;
  border-radius: 4px;
  text-align: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #2e6da4;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  cursor: pointer; }

.planning-equipe .equipe-day-group:last-child .copyBtns {
  width: 0; }

.planning-equipe .copyBtns .dayCopyBtn:hover {
  background-color: #286090; }

.planning-equipe .copyBtnsResponsive {
  display: none; }

.planning-equipe .equipe-one-day {
  width: 85%;
  display: flex;
  flex-direction: column; }

.planning-equipe .groupe-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  padding: .5rem; }

.planning-equipe .first-groupe {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.planning-equipe .groupe-planning {
  min-height: 181px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 50px -23px 0px 18px rgba(255, 255, 255, 0);
  box-shadow: 0px 10px 13px -7px #000000, 50px -23px 0px 18px rgba(255, 255, 255, 0); }
  .planning-equipe .groupe-planning .table-responsive {
    overflow: visible; }
    .planning-equipe .groupe-planning .table-responsive .planning-table-body {
      font-size: 1.1rem; }

.planning-equipe .equipe-one-day-edit {
  width: 100%;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem; }

.outilMultiselect .multiselect-parent {
  width: 100%;
  position: relative; }
  .outilMultiselect .multiselect-parent .dropdown-menu {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 100%;
    left: 0; }
  .outilMultiselect .multiselect-parent .btn:first-child {
    width: 100%; }

.filterSubGroup {
  display: flex;
  justify-content: left;
  gap: 1rem;
  margin-bottom: 2rem; }
  .filterSubGroup .form-group {
    width: 250px; }
  .filterSubGroup .filterDate {
    margin-right: 5rem; }

.weekFilterGroup {
  display: flex;
  justify-content: space-between;
  width: 50%; }

.weekFilterGroup .form-group {
  width: 45%; }

.equipe-filter-input, .filterInput {
  width: 100%; }

.day-filter-group {
  width: 50%; }

.day-filter-group .form-group {
  width: 45%; }

.filterInput {
  max-width: 250px; }

/* ==========================================================================
Commande
========================================================================== */
.interventions-commande-table-container {
  display: flex;
  justify-content: space-between; }

@media screen and (max-width: 1040px) {
  .planning-equipe {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 5rem; }
  .planning-equipe .equipe-day-group {
    width: 70%;
    flex-direction: column;
    min-height: 500px;
    align-items: center; }
  .planning-equipe .copyBtns {
    margin-top: 1rem;
    width: 15%;
    flex-direction: row; }
  .planning-equipe .copyBtnsBase {
    display: none; }
  .planning-equipe .copyBtnsResponsive {
    display: inherit; }
  .one-stat-container {
    margin-bottom: 3rem; }
    .one-stat-container .progress-bar-container {
      flex-direction: column; }
      .one-stat-container .progress-bar-container .technicien-name {
        margin-bottom: .5rem; }
      .one-stat-container .progress-bar-container .progress-bar-column {
        width: 100%; } }

@media screen and (max-width: 750px) {
  .planning-equipe .equipe-day-group {
    width: 100%; }
  .weekFilterGroup {
    width: 100%; }
  .day-filter-group {
    width: 100%; }
  .infos .last-imports {
    display: block; } }
